/** @jsx jsx */
import React from "react"
import { jsx } from "theme-ui"

const AccordionListItem = ({ index, isSelected, onClick, question, answer }) => {
  const selectedClass = isSelected ? ' selected' : ''
  const sectionId = `section-${index}`
  const buttonId = `accordion-${index}`

  return <li>
    <div className="accordion-q">
      <button
        id={buttonId}
        onClick={onClick}
        aria-controls={sectionId}
        aria-expanded={isSelected}
      >
      <span className="accordion-label">{question}
        <span className={`accordion-icon${selectedClass}`}></span>
      </span>
      </button>
    </div>
    <div
      className={`accordion-a${selectedClass}`}
      id={sectionId}
      aria-labelledby={buttonId}
    >
      {answer}
    </div>
  </li>
}

export const AccordionList = ({ content }) => {
  const [selected, setSelected] = React.useState(-1)

  const onClick = (e) => {
    e.preventDefault()
    const selectedInt = Number.parseInt(e.target.id.split('-')[1])
    setSelected(selected === selectedInt ? -1 : selectedInt)
  }

  const accordionList = content.map(({ question, answer }, i ) =>
    <AccordionListItem
      key={i}
      index={i}
      onClick={onClick}
      question={question}
      answer={answer}
      isSelected={selected === i}
    />
  )

  return <div className="accordion">
    <ul>
      {accordionList}
    </ul>
  </div>
}
