/** @jsx jsx */
import Seo from "../components/seo"
import { jsx } from "theme-ui"
import { Faq } from "../components/faq"
import Layout from "../components/layout"
// import { Breadcrumb } from "../components/breadcrumb"

const FaqPage = () =>
  <Layout className="faq-page">
    <Seo/>
    {/*<Breadcrumb title="FAQ"/>*/}
    <Faq thisPage="faq"/>
  </Layout>

export default FaqPage
