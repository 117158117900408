/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql, useStaticQuery } from "gatsby"
import { AccordionList } from "./accordion-list"
import { LiveChatButton } from "./live-chat-button"

const query = graphql`
  {
    faq: file(name: { eq: "faq" }) {
      props: childMarkdownRemark {
        body: html
        bits: frontmatter {
          title
        }
      }
    }
    results: allFile(filter: { relativePath: { regex: "/^faq\\//" } }) {
      files: edges {
        file: node {
          content: childMarkdownRemark {
            fields: frontmatter {
              question
              answer
              page
              faq
              home
            }
          }
        }
      }
    }
  }
`

export const Faq = ({ thisPage }) => {
  const data = useStaticQuery(query)
  const { faq: { props: { body, bits: { title: titleText } } } } = data
  const isFaqPage = thisPage === 'faq'
  const filter = ({ page, faq, home }) => {
    if (isFaqPage) return faq
    if (thisPage === 'home') return home
    return thisPage === page
  }
  // const titleText = 'Frequently asked questions'
  const title = isFaqPage
    ? <h1>{titleText}</h1>
    : <h2>{titleText}</h2>

  const { results: { files } } = data

  const faq = files
    .map(({ file: { content: { fields } } }) => fields)
    .filter(filter)

  return <section className="faq">
    <div className="faq-content">
      {title}
      <div dangerouslySetInnerHTML={{ __html: body }}/>
      <LiveChatButton label="Talk to us now" className="faq-talk"/>
      <AccordionList content={faq} />
    </div>
  </section>
}
